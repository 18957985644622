// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html, body, #root {\n    height: 100%;\n    margin: 0;\n  }\n  \n  .bg-black-custom {\n    background-color: #1C1C1C;\n    color: white; /* Para garantir que o texto seja visível sobre o fundo preto */\n    min-height: 100vh; /* Garante que o fundo preto ocupe a altura total da viewport */\n  }\n\n  .dropdown-toggle::after {\n    display: none;\n  }\n  \n  /* Importando a fonte Poppins do Google Fonts */\n  ", "",{"version":3,"sources":["webpack://./src/styles/board.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,YAAY,EAAE,+DAA+D;IAC7E,iBAAiB,EAAE,+DAA+D;EACpF;;EAEA;IACE,aAAa;EACf;;EAEA,+CAA+C","sourcesContent":["html, body, #root {\n    height: 100%;\n    margin: 0;\n  }\n  \n  .bg-black-custom {\n    background-color: #1C1C1C;\n    color: white; /* Para garantir que o texto seja visível sobre o fundo preto */\n    min-height: 100vh; /* Garante que o fundo preto ocupe a altura total da viewport */\n  }\n\n  .dropdown-toggle::after {\n    display: none;\n  }\n  \n  /* Importando a fonte Poppins do Google Fonts */\n  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
